import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75')
];

export const server_loads = [0,3,6,7];

export const dictionary = {
		"/(app)": [9,[2]],
		"/(app)/adls-system/adls-groups": [~10,[2]],
		"/(app)/adls-system/adls-groups/new": [12,[2]],
		"/(app)/adls-system/adls-groups/[id=id]/edit": [~11,[2]],
		"/(app)/adls-system/atm": [~13,[2]],
		"/(app)/adls-system/atm/[id=id]": [~14,[2]],
		"/(app)/adls-system/atm/[id=id]/change-history": [~15,[2]],
		"/(app)/adls-system/atm/[id=id]/edit": [~16,[2]],
		"/(app)/adls-system/atm/[type=atm_type]/new": [17,[2]],
		"/(app)/adls-system/ats-installations": [~23,[2]],
		"/(app)/adls-system/ats-installations/new": [~30,[2]],
		"/(app)/adls-system/ats-installations/[id=id]": [~24,[2]],
		"/(app)/adls-system/ats-installations/[id=id]/change-history": [~25,[2]],
		"/(app)/adls-system/ats-installations/[id=id]/edit": [~26,[2]],
		"/(app)/adls-system/ats-installations/[id=id]/maintenance": [~27,[2]],
		"/(app)/adls-system/ats-installations/[id=id]/maintenance/new": [~29,[2]],
		"/(app)/adls-system/ats-installations/[id=id]/maintenance/[mid=id]/edit": [~28,[2]],
		"/(app)/adls-system/ats": [~18,[2]],
		"/(app)/adls-system/ats/[id=id]": [~19,[2]],
		"/(app)/adls-system/ats/[id=id]/change-history": [~20,[2]],
		"/(app)/adls-system/ats/[id=id]/edit": [~21,[2]],
		"/(app)/adls-system/ats/[type=ats_type]/new": [22,[2]],
		"/(app)/admin/iam": [~31,[2,3]],
		"/(app)/admin/iam/users/new": [33,[2,3]],
		"/(app)/admin/iam/users/[uid]/edit": [~32,[2,3]],
		"/(app)/admin/system": [34,[2,3]],
		"/(auth)/auth/login": [72,[5,6]],
		"/(auth)/auth/logout": [~73,[5]],
		"/(auth)/auth/reset": [74,[5,7]],
		"/(app)/business-entities": [~35,[2]],
		"/(app)/business-entities/new": [39,[2]],
		"/(app)/business-entities/[id=id]": [~36,[2]],
		"/(app)/business-entities/[id=id]/change-history": [~37,[2]],
		"/(app)/business-entities/[id=id]/edit": [~38,[2]],
		"/(app)/certificates/enercon/scada": [~40,[2]],
		"/(app)/certificates/enercon/scada/new": [43,[2]],
		"/(app)/certificates/enercon/scada/[id=id]": [~41,[2]],
		"/(app)/certificates/enercon/scada/[id=id]/edit": [~42,[2]],
		"/(app)/legal": [44,[2]],
		"/(app)/map": [45,[4]],
		"/(app)/planning/drafts": [~46,[2]],
		"/(app)/planning/drafts/import": [50,[2]],
		"/(app)/planning/drafts/new": [51,[2]],
		"/(app)/planning/drafts/[id=id]": [~47,[2]],
		"/(app)/planning/drafts/[id=id]/change-history": [~48,[2]],
		"/(app)/planning/drafts/[id=id]/edit": [~49,[2]],
		"/(app)/projects": [~52,[2]],
		"/(app)/projects/new": [58,[2]],
		"/(app)/projects/[id=id]": [~53,[2]],
		"/(app)/projects/[id=id]/change-history": [~54,[2]],
		"/(app)/projects/[id=id]/commissioning": [~55,[2]],
		"/(app)/projects/[id=id]/documents": [~56,[2]],
		"/(app)/projects/[id=id]/edit": [~57,[2]],
		"/(app)/statistics": [~59,[2]],
		"/tmp/project-information/[id=id]": [~75,[8]],
		"/(app)/user/profile": [~60,[2]],
		"/(app)/user/service-accounts": [~61,[2]],
		"/(app)/wind-parks": [~62,[2]],
		"/(app)/wind-parks/new": [64,[2]],
		"/(app)/wind-parks/[id=id]/edit": [~63,[2]],
		"/(app)/wind-turbines": [~65,[2]],
		"/(app)/wind-turbines/new": [71,[2]],
		"/(app)/wind-turbines/[id=id]": [~66,[2]],
		"/(app)/wind-turbines/[id=id]/adls-system": [~67,[2]],
		"/(app)/wind-turbines/[id=id]/change-history": [~68,[2]],
		"/(app)/wind-turbines/[id=id]/edit": [~69,[2]],
		"/(app)/wind-turbines/[id=id]/edit/configuration": [~70,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';