import { TypeEnum } from '$lib/modules/enums/atm/type-enum.js';

/**
 * Check if a string is a valid ATM type.
 *
 * @param {string} type - The value to check
 * @return {boolean} True if the value is a valid ATM type, false otherwise
 */
export function match(type) {
    if (type == null) return false;

    return TypeEnum.getName(type) != null;
}
