/**
 * Check if a string is a valid MongoDB ObjectID
 * A valid ObjectID is a 24 character, hex string
 *
 * @param {string} id - The value to check
 * @return {boolean} True if the value is a valid ObjectID, false otherwise
 */
export function match(id) {
    const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');
    return checkForHexRegExp.test(id);
}
