import appConfig from '$lib/app.config.js';

// get a list of known proxy target params
const TARGET_PARAMS = appConfig.apiUrlRewrite.routes.map(
    /**
     * @param {import('$lib/types').RewriteApiRequest.Route } route
     */
    (route) => route.path.replace('/api/', '')
);

/** @type {import('@sveltejs/kit').ParamMatcher} */
export function match(param) {
    return TARGET_PARAMS.includes(param);
}
