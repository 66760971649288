import { TypeEnum } from '$lib/modules/enums/ats/type-enum.js';

/**
 * Check if a string is a valid ATS type.
 *
 * @param {string} type - The value to check
 * @return {boolean} True if the value is a valid ATS type, false otherwise
 */
export function match(type) {
    if (type == null) return false;

    return TypeEnum.getName(type) != null;
}
