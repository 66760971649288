import * as Sentry from '@sentry/sveltekit';
import { init as initFirebase } from '$lib/modules/firebase.js';
import { PUBLIC_GCP_SERVICE_NAME, PUBLIC_GCP_SERVICE_REVISION } from '$env/static/public';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: 'https://12f1e1cf56d2f5bb60089bd0c2c44947@o4507294924668928.ingest.de.sentry.io/4507294928797776',
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.4,
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: 'system',
            formTitle: 'Send feedback',
            submitButtonLabel: 'Send feedback',
            messagePlaceholder: 'Help us improve by sending feedback!',
            successMessageText: 'Submitted feedback. Thank you!',
            buttonLabel: '',
        }),
        // Add browser profiling integration to the list of integrations
        Sentry.browserProfilingIntegration(),
    ],
    release: `${PUBLIC_GCP_SERVICE_NAME}@${PUBLIC_GCP_SERVICE_REVISION}`,
    environment: import.meta.env.VITE_NODE_ENV,
    enabled: import.meta.env.VITE_NODE_ENV === 'production' || import.meta.env.VITE_NODE_ENV === 'development',
});

initFirebase();

export const handleError = Sentry.handleErrorWithSentry(async function _handleError({ error }) {
    console.error(error?.error?.message || error.toString());
});
