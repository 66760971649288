import {
    PUBLIC_IAM_API_URL,
    PUBLIC_MESSAGING_API_URL,
    PUBLIC_REPORTING_API_URL,
    PUBLIC_NOTIFICATION_API_URL,
    PUBLIC_OPERATING_API_URL,
    PUBLIC_TILES_API_URL,
} from '$env/static/public';

/**
 * App specific configuration settings.
 */
export default Object.freeze({
    // configuration options for the app internal proxy
    apiUrlRewrite: {
        routes: [
            {
                path: '/api/notifications',
                rewrite: '/api',
                target: PUBLIC_NOTIFICATION_API_URL,
                // will automatically try to set the authorization header with the session cookie
                includeCredentials: true,
            },
            {
                path: '/api/iam',
                rewrite: '/api',
                target: PUBLIC_IAM_API_URL,
                includeCredentials: true,
            },
            {
                path: '/api/operating',
                rewrite: '/api',
                target: PUBLIC_OPERATING_API_URL,
                // will automatically try to set the authorization header with the session cookie
                includeCredentials: true,
            },
            {
                path: '/api/messaging',
                rewrite: '/api',
                target: PUBLIC_MESSAGING_API_URL,
                // will automatically try to set the authorization header with the session cookie
                includeCredentials: true,
            },
            {
                path: '/api/tiles',
                rewrite: '/api',
                target: PUBLIC_TILES_API_URL,
                // will automatically try to set the authorization header with the session cookie
                includeCredentials: true,
            },
            {
                path: '/api/reporting',
                rewrite: '/api',
                target: PUBLIC_REPORTING_API_URL,
                // will automatically try to set the authorization header with the session cookie
                includeCredentials: true,
            },
        ],
    },
    // global logger config
    logger: {},
});
